import type { ReactNode } from "react";

import { AuthHeader, AuthHeaderWrapper, AuthFooter } from "@shared/components";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { CountrySelect } from "@unauthenticated/signupSignin/components/CountrySelect";
import { Page } from "@unauthenticated/signupSignin/components/Page";
import { PageContent } from "@unauthenticated/signupSignin/components/PageContent";

interface Props {
  children: ReactNode;
}

const SignInLayout = ({ children }: Props) => {
  const { isEmerson } = useSharedGlobalConfig();
  const route = isEmerson ? authRoutes.emerson.login : authRoutes.login;

  return (
    <Page>
      <PageContent>
        <AuthHeaderWrapper>
          <AuthHeader route={route} />
          {!isEmerson && <CountrySelect />}
        </AuthHeaderWrapper>
        {children}
      </PageContent>
      <AuthFooter />
    </Page>
  );
};

export { SignInLayout };
