import { useSearchParams } from "@shared/react-router-dom";
import { isSSR } from "@shared/utils/fullscriptEnv/fullscriptEnv";

const convertToCamelCase = (str: string) => {
  // Remove the underscore and capitalize the second word, assuming there's only
  // one underscore in the string.
  return str.replace(/_[a-z]/i, match => match.charAt(1).toUpperCase());
};

const getReferrerSearchParams = () => {
  if (isSSR()) return null;

  if (document.referrer) {
    const referrerUrl = new URL(document.referrer);

    return new URLSearchParams(referrerUrl.search);
  }

  return new URLSearchParams();
};

const useUnauthUrlFields = ({ isCamelCaseKeys } = { isCamelCaseKeys: false }) => {
  const [searchParams] = useSearchParams();
  const referrerSearchParams = getReferrerSearchParams();

  const payload = {
    utm_campaign: searchParams?.get("utm_campaign") || referrerSearchParams?.get("utm_campaign"),
    utm_medium: searchParams?.get("utm_medium") || referrerSearchParams?.get("utm_medium"),
    utm_source: searchParams?.get("utm_source") || referrerSearchParams?.get("utm_source"),
    utm_id: searchParams?.get("utm_id") || referrerSearchParams?.get("utm_id"),
    utm_term: searchParams?.get("utm_term") || referrerSearchParams?.get("utm_term"),
    utm_content: searchParams?.get("utm_content") || referrerSearchParams?.get("utm_content"),
    utm_channel: searchParams?.get("utm_channel") || referrerSearchParams?.get("utm_channel"),
    utm_adgroup: searchParams?.get("utm_adgroup") || referrerSearchParams?.get("utm_adgroup"),
    utm_matchtype: searchParams?.get("utm_matchtype") || referrerSearchParams?.get("utm_matchtype"),
    utm_device: searchParams?.get("utm_device") || referrerSearchParams?.get("utm_device"),
  };

  Object.keys(payload).forEach(key => {
    if (payload[key] === null || payload[key] === undefined) {
      delete payload[key];
    } else if (isCamelCaseKeys) {
      payload[convertToCamelCase(key)] = payload[key];
      delete payload[key];
    }
  });

  return payload;
};

export { useUnauthUrlFields };
