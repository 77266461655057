import { useTranslation } from "react-i18next";

import { Pill } from "@aviary";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import type { PatientAttributes } from "@unauthenticated/signupSignin/data/types";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./StoreOffersPill.styles";
interface Props {
  patientAttributes: PatientAttributes;
}

const StoreOffersPill = ({ patientAttributes }: Props) => {
  const { t } = useTranslation();
  const { isUS } = useSharedGlobalConfig();
  const { storeDiscount } = patientAttributes;

  const renderDiscountPill = () => {
    if (!storeDiscount) return null;

    return (
      <Pill isColor="success" css={styles.pill}>
        {t(l.sharedCommon.discountPill, { discount: storeDiscount })}
      </Pill>
    );
  };

  const renderFreeShipping = () =>
    !isUS ? t(l.patientRxAuth.FreeShippingCA) : t(l.patientRxAuth.FreeShippingUS);

  return (
    <div css={styles.pillContainer}>
      {renderDiscountPill()}
      <Pill isColor="success" css={styles.pill}>
        {renderFreeShipping()}
      </Pill>
    </div>
  );
};

export { StoreOffersPill };
